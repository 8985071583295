import React from "react";

import WeeNetwork from "assets/icons/wee-network.svg";
import DiscountSimulator from "assets/images/discount-simulator.png";
import EnhancedServiceQuality from "assets/images/enhanced-service-quality.svg";
import NegotiationInsights from "assets/images/negotiation-insights.svg";
import NetworkFin from "assets/images/network-fin.svg";
import NetworkMonitor from "assets/images/network-monitor.png";
import NetworkOptimizationGraph from "assets/images/network-optimization-graph.svg";
import OptimizedNetworkStructure from "assets/images/optimized-network-structure.svg";
import ProviderBenchmarkAnalysis from "assets/images/provider-benchmark-analysis.png";
import Card from "components/Card";
import Divider from "components/Divider";
import Hero from "components/Hero";
import HowWeDoIt from "components/HowWeDoIt";
import NetworkOptimizationServices from "components/NetworkOptimizationServices";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import SEO from "components/SEO";
import WhatWeOffer from "components/WhatWeOffer";

const NetworkOptimizationPage = () => (
  <PageContainer>
    <Hero
      title="Network Optimization"
      divider={<Divider icon={<img src={WeeNetwork} alt="wee-network" />} />}
      img={
        <Hero.HeroImage
          src={NetworkFin}
          alt="brain-mesh-people"
          loading="lazy"
        />
      }
    >
      Qantev’s AI assesses the entirety of a health care network and recommends
      the best actions to be performed, in real time.
    </Hero>

    <WhatWeOffer offerText="Qantev identifies opportunities to save costs and increase efficiency, be it through negotiating savings or steering members towards better health service providers." />

    <Card
      title="Compare similar providers in details"
      dividerText="PROVIDER BENCHMARK ANALYSIS"
      imgSrc={ProviderBenchmarkAnalysis}
      imgAlt="provider-benchmark-analysis"
      alignImgLeft
      imgShadow
      imgShadowReverse
    >
      <p>
        Qantev uses a unique approach leveraging our profiling and simulation
        capabilities to make robust apple with apple comparisons.
      </p>
      <p>
        Compared to black box clustering algorithms, Qantev approach is highly
        explainable and auditable.
      </p>
    </Card>

    <Card
      title="Opportunity analysis for each provider in the network"
      dividerText="NETWORK & PRICING ANALYSIS"
      imgSrc={NetworkMonitor}
      imgAlt="network-monitor"
      imgShadow
    >
      <p>
        Our recommendation engine builds automated reports identifying
        inefficiencies and pricing gaps in your helth care network.
      </p>
      <p>
        Qantev&apos;s simulation capabilities allows the user to forecast the
        consequences of the different course of actions at his disposal.
      </p>
    </Card>

    <Card
      title="Forecast member behaviour of each action taken"
      dividerText="SIMULATION & FORECAST ANALYSIS"
      imgSrc={DiscountSimulator}
      imgAlt="discount-simulator"
      imgShadow
      imgShadowReverse
      alignImgLeft
    >
      <p>
        Qantev simulators can predict the potential impact of any management
        action on spending, volume of members, the overall quality of care and
        multiple other KPIs.
      </p>
      <p>
        Network Managers can easily identify the right level of discount to
        negotiate to achieve their targets and easily build strategies to steer
        members.
      </p>
    </Card>

    <NetworkOptimizationServices>
      <NetworkOptimizationServices.Item
        caption={
          <>
            NEGOTIATION
            <br />
            INSIGHTS
          </>
        }
        imgSrc={NegotiationInsights}
        imgAlt="negotitation-insights"
      />
      <NetworkOptimizationServices.Item
        caption={
          <>
            OPTIMIZED
            <br />
            NETWORK STRUCTURE
          </>
        }
        imgSrc={OptimizedNetworkStructure}
        imgAlt="optimized-network-structure"
      />
      <NetworkOptimizationServices.Item
        caption={
          <>
            ENHANCED
            <br />
            QUALITY OF SERVICE
          </>
        }
        imgSrc={EnhancedServiceQuality}
        imgAlt="enhanced-quality-of-service"
      />
    </NetworkOptimizationServices>

    <HowWeDoIt description="Combining smart UI and a deep understanding of client needs, Qantev’s strong data foundation allows for recommendations based on hard data not hunches. We combine historical data and expert models to simulate and forecast the impact of client decisions.">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={NetworkOptimizationGraph} alt="network-optimization-graph" />
      </div>
    </HowWeDoIt>
    <Next
      text={
        <>
          Claims
          <br />
          Management
        </>
      }
      to="/product/claims-management"
    />
  </PageContainer>
);

export default NetworkOptimizationPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Network Optimization" pathname={pathname} />
);
